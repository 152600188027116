.type-list {
  width: 50%;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 8px;
  overflow-y: auto;
}

.type-list::-webkit-scrollbar,
.checkbox-content::-webkit-scrollbar,
.tags-container::-webkit-scrollbar,
.tag-parent::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.type-list::-webkit-scrollbar-button,
.checkbox-content::-webkit-scrollbar-button,
.tags-container::-webkit-scrollbar-button,
.tag-parent::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.type-list::-webkit-scrollbar-corner,
.checkbox-content::-webkit-scrollbar-corner,
.tags-container::-webkit-scrollbar-corner,
.tag-parent::-webkit-scrollbar-corner {
  background-color: transparent;
}

.type-list::-webkit-scrollbar-thumb,
.checkbox-content::-webkit-scrollbar-thumb,
.tags-container::-webkit-scrollbar-thumb,
.tag-parent::-webkit-scrollbar-thumb {
  background-color: #e6e7f2 !important;
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
}

.ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  box-sizing: border-box;
}

.type-list > ul {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.type-list li {
  position: relative;
  padding: 12px 16px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #090324;
}

.checkbox-content {
  width: 50%;
  padding: 16px 24px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.checkbox-list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 100%;
}

.checkbox-list > li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.checkbox-list > li > label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-size: inherit;
  letter-spacing: 0;
  color: #090324;
}

.type-list li::after {
  position: absolute;
  height: 16px;
  right: 12px;
  content: url(./components/arrow-right.svg);
}

.checkbox-list > li > label input[type="checkbox"]:checked + .checkbox-block {
  background-image: url(./components/checkbox-checked.svg);
  background-size: cover;
  background-origin: border-box;
  border: none;
}

.checkbox-block {
  width: 22px;
  height: 22px;
  border: 1px solid #9093b5;
  border-radius: 4px;
  margin-right: 17px;
}

.type-list li.active {
  background-color: #f2f3ff;
  border-radius: 8px;
  color: #4a59ff;
}

.type-list li.items-chosen {
  color: #4a59ff;
}

.tags-container {
  padding: 6px 24px 14px 14px;
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
}

.tag {
  position: relative;
  color: #fefefe;
  border-radius: 6px;
  background-color: #4a59ff;
  padding: 8px 30px 8px 12px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  cursor: pointer;
}

.tag::after {
  position: absolute;
  top: 9px;
  right: 13px;
  content: url(./components/cross-tag.svg);
}

.tag-parent {
  max-height: 150px;
  overflow-y: auto;
}

.disabled-tag {
  position: relative;
  border-radius: 6px;
  padding: 8px 30px 8px 12px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  background-color: #e6e7f2;
  padding-right: 12px;
  color: #090324;
}
