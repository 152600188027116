.modal-btn {
  filter: none;
  padding: 16px 32px;
  border: 1px solid #d4d6ea;
  border-radius: 8px;
  width: 100%;
  background: transparent;
  margin: 0;
  line-height: 1.15;
  font-size: 14px;
  font-family: inherit;
}

.modal-btn.btn-blue {
  width: 55%;
  background: #4a59ff;
  border: none;
  color: #ffffff;
}
