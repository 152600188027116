
@media only screen and (max-width: 760px) {
    .pincode-input-text{
        border-radius: 15px;
        width: 40px !important;
        height: 40px !important
    }
  }

@media only screen and (min-width: 760px) {
  .pincode-input-text{
    border-radius: 10px;
    width: 39px !important;
    height: 39px !important
}
}


@media (min-width: 766px) and (max-width:1344px){
  .pincode-input-text{
    border-radius: 10px;
    width:30px !important;
    height: 30px !important
}
} 



/* @media (min-width: 1150px) and (max-width:1344px){
  .pincode-input-text{
    border-radius: 10px;
    width:30px !important;
    height: 30px !important
}
}  */




@media only screen and (min-width: 1500px) {
    .pincode-input-text{
      border-radius: 15px;
      width: 45px !important;
      height: 45px !important
  }
  }