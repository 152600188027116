.newDesignSelect {
  margin: 0 !important;
  border: 1px solid #ced3df;
  filter: drop-shadow(0px 4px 4px rgba(74, 89, 255, 0.35));
  font-size: 16px;
  flex-shrink: 100;
  line-height: 24px;
  width: 100%;
  height: 70px;
  font-family: inherit;
  color: #9093b5;
  background-color: #fefefe;
  padding-inline: 2em;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.newDesingSelectParent {
  position: relative;
}

.newDesingSelectParent:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 20px;
  border: solid #9093b5;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
