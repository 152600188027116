.save-icon{
    position:relative;
    border:1px solid lightgrey;
    background:white;
    height:45px;
    width:35px;
    display:block;
    padding-top:10px;
    
    -moz-border-radius:3px;
    border-radius:3px;
  }
  
  .save-icon:before{
     content:"";
     position:absolute;
     top:0;
     right:0;
     border-width:0 10px 10px 0;
     border-style:solid;
     border-color:#d9d9d9 #b6b9ba;
  }
  
  .save-icon:after{
    content:"✓";
    color:green;
    font-size:30px;
    position:absolute;
    top:5%;left:20%;
    -webkit-transform:scale(0);
    -moz-transform:scale(0);
    transform:scale(0);
    
    -webkit-animation: pop 0.5s 3s forwards;
       -moz-animation: pop 0.5s 3s forwards;
            animation: pop 0.5s 3s forwards;  
  }
  
  
  
  .loader{
    background:#e2e2e2;
    width:80%;
    height:5px;
    display:block;
    margin:3px auto;
    
    position:relative;
    overflow:hidden;
    
    
    -webkit-animation:fade-loaders 0.2s 3s forwards;
       -moz-animation:fade-loaders 0.2s 3s forwards;
            animation:fade-loaders 0.2s 3s forwards;
  }
  
  
  .loader:after{
    content: "";
    background:#2c3033;
    width:0;
    height:5px;
    position:absolute;
    top:0;left:0;
  }
  
  .loader:first-child:after{
    -webkit-animation: loader 0.4s 1s forwards;
       -moz-animation: loader 0.4s 1s forwards;
            animation: loader 0.4s 1s forwards;
  }
  
  
  .loader:nth-child(2n):after{
    -webkit-animation: loader 0.4s 1.5s forwards;
       -moz-animation: loader 0.4s 1.5s forwards;
            animation: loader 0.4s 1.5s forwards;
  }
  
  .loader:nth-child(3n):after{
    -webkit-animation: loader 0.4s 2s forwards;
       -moz-animation: loader 0.4s 2s forwards;
            animation: loader 0.4s 2s forwards;
  }
  
  
  @-webkit-keyframes loader {0%{ width: 0%; }100% { width: 100%; }}
     @-moz-keyframes loader {0%{ width: 0%; }100% { width: 100%; }}
          @keyframes loader {0%{ width: 0%; }100% { width: 100%; }}
  
  @-webkit-keyframes pop {
    0%   { -webkit-transform: scale(0); }
    100% { -webkit-transform: scale(1); }
  }
  @-moz-keyframes pop {
    0%   { -moz-transform: scale(0); }
    100% { -moz-transform: scale(1); }
  }
  @keyframes pop {
    0%   { transform: scale(0); }
    100% { transform: scale(1); }
  }
  
  @-webkit-keyframes fade-loaders{
    0%   { opactity:1; }
    100% { opacity:0; }
  }
  @-moz-keyframes fade-loaders{
    0%   { opactity:1; }
    100% { opacity:0; }
  }
  @keyframes fade-loaders{
    0%   { opactity:1; }
    100% { opacity:0; }
  }