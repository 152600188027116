
.hidden {
    display: none !important;
}

/* .Mui-focused {
    border: none !important;
    outline: 'none' !important;

} */

/* {
    border: 1px solid yellow !important;
} */


#media-control{
    margin-bottom: 10px !important;
}