.parDifHelper {
  width: 100%;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.parDifHelper .seper {
  height: 15px;
  width: 2px;
  background-color: grey;
}

.parDifHelper .arrowLeft,
.parDifHelper .arrowRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parDifHelper .line {
  height: 2px;
  width: 8.5em;
  background-color: grey;
  /* animation: arrowAnimation 1.5s infinite; */
}

.parDifHelper .arrowLeft .tip {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.parDifHelper .arrowRight .tip {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
