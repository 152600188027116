.wizardDrawer .MuiDrawer-paper {
  max-width: 550px !important;
}

.wizard .scrollhost::-webkit-scrollbar {
  display: none;
}

.wizard .css-eimhud-MuiSvgIcon-root {
  color: black;
}

.wizardTabs,
.wizardTabs button {
  background-color: #020841 !important;
  color: white !important;
  border: none;
}

.wizardTabs button {
  border-radius: unset;
}

.wizardTabIndicator {
  box-shadow: 0 3px 2px -2px white !important;
  border: none !important;
}

.wizard .Mui-disabled {
  opacity: 0.6 !important;
}

.wizard .wizardSearch {
  /* backdrop-filter: blur(10px); */
  background-color: white;
}

.wizard .css-mz2s1p-MuiButtonBase-root-MuiListItemButton-root {
  padding-right: 4px;
}

.wizard .css-sq0eqm-MuiButtonBase-root-MuiListItemButton-root {
  padding-left: 1px;
}

.wizard .wizardSearch input {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px;
  border: 1px solid transparent;
  border-bottom: 1px solid grey;
  font-size: 1.2rem;
  background-color: white;
  color: black;
  outline: none;
  position: relative;
  width: 100%;
}
.wizard .wizardSearch input:focus {
  border: 1px solid #1a73e8;
  border-radius: 5px;
}

.wizard .wizardSearch label {
  pointer-events: none;
  font-size: 1rem;
  color: #1a73e8;
  position: absolute;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 1.9em;
  left: 1.8em;
  padding: 0 0.5em;
}

.wizard .wizardSearch input:focus + label {
  transform: translate(1em, -1em);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
}

.wizard .activeSearch {
  border: 1px solid #1a73e8 !important;
  border-radius: 5px;
}

.wizard .activeSearch + label {
  transform: translate(1em, -1em);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
}

.wizard .titleInput {
  padding-inline: 0.5em;
  position: relative;
  background-color: white !important;
}

.wizard .titleInput input {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px;
  border: 1px solid transparent;
  border-bottom: 1px solid grey;
  font-size: 1.2rem;
  background-color: white;
  color: black;
  outline: none;
  width: 100%;
}

.wizard .titleInput input:focus {
  border: 1px solid #1a73e8;
  border-radius: 5px;
}

.wizard .titleInput label {
  pointer-events: none;
  font-size: 1rem;
  color: #1a73e8;
  position: absolute;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0.5em;
  left: 0.5em;
  padding: 0 0.5em;
  letter-spacing: 1px;
}

.wizard .titleInput input:focus + label {
  transform: translate(0.8em, -1.4em);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
}

.wizard .activeTitle {
  border: 1px solid #1a73e8 !important;
  border-radius: 5px;
}

.wizard .activeTitle + label {
  transform: translate(0.8em, -1.4em);
  font-size: 0.8rem;
  background-color: white;
  border-radius: 10px;
}

.wizard .wizardButtons {
  background-color: white;
}

.wizard .wizardButtons svg {
  padding-bottom: 0.1em;
  color: white;
  margin-left: 0.3em;
}

.wizard .wizardButtons .Btn {
  background-color: #020841;
  padding: 9px 15px;
}
