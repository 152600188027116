.rotate270 {
    transform: rotate(270deg);
}

.react-flow__node-default .react-flow__handle {
    background: none !important;
}

.react-flow__handle {
    border: none;
}

.react-flow__handle.connectable {
    pointer-events: none;
}

.mainTab-hidden {
    display: none;
}

#heartIcon{
    cursor: pointer;
    font-size: 1.3rem;
    color: #1A73E8;
}

.rh5v-DefaultPlayer_controls {
    overflow: visible;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}


.videoIcon:hover {
    color:white;
    cursor: pointer;

}

.videomodal:hover {
    cursor: pointer
}

.hidden {
    display: none;
}


.icons {
    cursor: pointer;
}

.rh5v-DefaultPlayer_video {
    height: 100% !important;
}
.MuiTreeItem-label {
  font-size:0.8rem !important;
}

.rh5v-DefaultPlayer_component {
    height: 440px;
  }

/* MuiSvgIcon-root MuiSvgIcon-colorInfo MuiSvgIcon-fontSizeMedium icons unfavorite_icon css-1rr5so0-MuiSvgIcon-root */
/* MuiSvgIcon-root MuiSvgIcon-colorInfo MuiSvgIcon-fontSizeMedium icons favorite_icon css-1qgtjl6-MuiSvgIcon-root */
