:root {
  --font-family-bold: "SoleilBold", sans-serif;
  --font-family-book: "SoleilBook", sans-serif;
  --font-family-light: "SoleilLight", sans-serif;
  --font-family-regular: "SoleilRegular", sans-serif;
  overflow-x: hidden;
}

@font-face {
  font-family: "SoleilBold";
  font-display: swap;
  src: url("./woffs/SoleilBold.woff2") format("woff2");
}

@font-face {
  font-family: "SoleilBook";
  font-display: swap;
  src: url("./woffs/SoleilBook.woff2") format("woff2");
}

@font-face {
  font-family: "SoleilLight";
  font-display: swap;
  src: url("./woffs/SoleilLight.woff2") format("woff2");
}

@font-face {
  font-family: "SoleilRegular";
  font-display: swap;
  src: url("./woffs/SoleilRegular.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

html {
  background-color: #eef0f4;
  font-family: var(--font-family-book);
  height: 100vh;
  width: 100vw;
  position: relative;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection,
::selection {
  text-shadow: none;
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-family-bold);
  color: #090324;
  margin: 0;
}

.myloginheader {
  position: relative;
  padding: 34px 0 45px;

  .header-bg {
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: 0;
  }
}

.container {
  max-width: 1156px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;

  .flex-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;

    .link-div {
      display: flex;
      align-items: center;
    }

    .title-header {
      margin-bottom: -3px;
      margin-left: 20px;
      font-family: var(--font-family-bold);
      font-size: 18px;
      letter-spacing: 0;
    }
  }

  .form-container-login {
    margin: 12vh auto 12vh;
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-form {
      font-size: 24px;
    }
    .form-content {
      margin: 40px 0 12px;
      gap: 20px;

      .label-email,
      .label-firstname,
      .label-lastname,
      .label-phonenumber {
        color: black !important;
      }
    }
    .flex-table {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .form-submit-btn {
      width: 100%;
      background: #4a59ff;
      color: #fefefe;
      font-family: var(--font-family-bold);
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0;
      padding: 20px 24px;
      border-radius: 10px;
      border: none;
    }
  }
}

/* main */

.text-centered {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.form-container-password {
  margin: 12vh auto 12vh;
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-form2 {
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-form2up {
  width: 640px;
}

.form-input {
  gap: 8px;
}

.resend-pin-text {
  font-family: "SoleilRegular", sans-serif !important;
  font-size: 16px !important;
}

.PrevButtonLogin {
  font-size: 16px;
  font-family: "SoleilRegular", sans-serif;
  line-height: 20px;

  /* color: #090324; */
  color: #344767;
  font-weight: 400 !important;
  margin-top: 10px;

  /* margin: 0 !important; */
  background: transparent;

  /* font-family: inherit; */
}

.form-input > {
  label {
    font-family: var(--font-family-bold);
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
  }

  input,
  div > input {
    padding: 16px 20px;
    width: 100%;
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    border: 1px solid #4a59ff;
    border-radius: 10px;
  }

  input {
    &::placeholder,
    &::-ms-input-placeholder {
      color: #9093b5;
    }
  }
}

.submit_btn_signup {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-form-div {
  margin-left: 85px !important;
}

.form-submit-btn {
  width: 100%;
  background: #4a59ff;
  color: #fefefe;
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
  padding: 20px 24px;
  border-radius: 10px;
  border: none;
}

.form-submit-btn2 {
  width: 73%;
  background: #4a59ff;
  color: #fefefe;
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
  padding: 20px 24px;
  border-radius: 10px;
  border: none;
}

.input-phone-container {
  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    top: 18px;
    left: 20px;
    right: 12px;

    /* content: url("../img/usa.svg"); */
    width: 38px;
  }

  &::before {
    background-color: #eef0f4;
    content: "";
    height: 40px;
    width: 1px;
    left: 78px;
    top: 8px;
    position: absolute;
  }
}

.react-tel-input .form-control {
  border: 1px solid #4a59ff;
  border-radius: 10px;
}

.form-input-number {
  height: 65px;
  width: 65px !important;
  border-radius: 10px;
  background: #fefefe;
  border: 1px solid #4a59ff;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="password"] {
    -moz-appearance: textfield;
    font-size: 24px;
    text-align: center;
  }
}

input[id="code-verification"] {
  display: none;
}

.verification-grid {
  gap: 15px;
}

.form-text {
  margin: 3px 0 20px;
  color: #394055;
  font-size: 16px;
  font-family: var(--font-family-book);
}

.form-content.flex-table.verification-status {
  margin: 0;
}

.flex-row.verification-grid {
  margin-bottom: 25px;
}

.back-btn {
  margin-top: 20px;
}

/* footer */

footer > div.container.flex-table {
  gap: 130px;
}

.footer-main {
  padding-top: 38px;
  border-top: 1px solid #0000001a;
}

.footer-text,
.footer-link {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  color: #020c2d;
  text-decoration: none;
  margin: 0;
}

.btn-for-signup {
  margin-top: 25px;
}

.link-hide {
  color: #eef0f4 !important;
}

.footer-bottom-content {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 35px;

  > div.flex-row {
    gap: 30px;
  }
}

.footer-top-content {
  justify-content: space-between;
  align-items: flex-start;
}

.footer-section-form {
  gap: 48px;
  padding-right: 10px;
  max-width: 50%;

  > img {
    height: 30px;
    width: 147px;
  }
}

.footer-section-list {
  width: 50%;
  justify-content: space-between;

  > div > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.footer-list-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #4a59ff;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer-list {
  gap: 20px;
}

.footer-list-item {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
}

.footer-form {
  background-color: #ffffff;
  box-shadow: 0 4px 15px 0 #aaaab940;
  border-radius: 10px;
}

.input-form-email {
  min-width: 60px;
  padding: 22px 0 20px 25px;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #020c2d;
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;

  &::placeholder {
    font-family: var(--font-family-regular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    color: #020c2d;
    opacity: 0.2;
  }
}

.submit-email-btn {
  background: #4a59ff;
  border-radius: 10px;
  width: 152px;
  height: 50px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  outline: none;
  margin: 4px;
}

.footer-minify {
  /* padding-top: 50px; */
  padding-bottom: 6px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media screen and (max-height: 800px) {
  /* Your CSS rules for screens with height less than or equal to 800 pixels */
  .footer-minify {
    /* padding-top: 50px; */
    position: relative;
    padding-bottom: 6px;
    width: 100%;
    margin-top: 60px;
  }

  .form-input > input,
  .form-input > div > input {
    padding: 12px 20px;
  }

  .sign-up-form .form-content {
    margin: 20px 0 32px;
  }

  .react-tel-input .form-control {
    padding: 13.5px 14px 13.5px 58px;
  }

  .form-submit-btn {
    padding: 15px 24px;
  }

  .footer-main {
    border-top: 0px solid #0000001a;
  }

  .title-header-signup {
    display: none;
  }
  .link-div-signup {
    display: none !important;
  }
  .container .form-container-login .form-submit-btn {
    padding: 15px 24px;
  }
}
