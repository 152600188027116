.Btn-hidden {
    display: none !important;
}



.css-1j5gnpl-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    /* padding: 0 !important; */
    /* padding-top: 1px !important; */
}


#demo-select-small{
    height: 35px !important;
}

