h1,
h2,
h3,
h4 {
  font-family: var(--font-family-bold);
  color: #090324;
  margin: 0;
}

.myloginheader {
  position: relative;
  padding: 34px 0 45px;
}

.header-bg {
  position: absolute;
  top: -15px;
  right: -15px;
  bottom: 0;
}

.container {
  max-width: 1156px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.flex-table {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.title-header {
  margin-bottom: -3px;
  margin-left: 20px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  letter-spacing: 0;
}

/* main */

.text-centered {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.form-container {
  margin: 10vh auto 8vh;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-form {
  width: 640px;
}

.form-content {
  margin: 40px 0 12px;
  gap: 20px;
}

.form-input {
  gap: 8px;
}

.form-input > input,
.form-input > div > input {
  padding: 16px 20px;
  width: 100%;
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  border: 1px solid #4a59ff;
  border-radius: 10px;
}

.form-input > input::placeholder,
.form-input > input::-ms-input-placeholder,
.form-input > input::-ms-input-placeholder {
  color: #9093b5;
}

.submit_btn_signup {
  margin-top: -20px;
}

.input-phone-container {
  position: relative;
  width: 100%;
}

.input-phone-container::after {
  position: absolute;
  top: 18px;
  left: 20px;
  right: 12px;
  /* content: url("../img/usa.svg"); */
  width: 38px;
}

.input-phone-container::before {
  background-color: #eef0f4;
  content: "";
  height: 40px;
  width: 1px;
  left: 78px;
  top: 8px;
  position: absolute;
}

.react-tel-input .form-control {
  border: 1px solid #4a59ff;
  border-radius: 10px;
}

.form-input-number::-webkit-inner-spin-button,
.form-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-input-number[type="password"] {
  -moz-appearance: textfield;
  font-size: 30px;
  text-align: center;
}

input[id="code-verification"] {
  display: none;
}

.verification-grid {
  gap: 15px;
}

.form-text2 {
  margin: 8px 0 20px;
  color: #394055;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.form-text {
  margin: 8px 0 20px;
}

.form-content.flex-table.verification-status {
  margin: 0;
}

.flex-row.verification-grid {
  margin-bottom: 25px;
}

.back-btn {
  margin-top: 20px;
}

/* footer */
footer > div.container.flex-table {
  gap: 130px;
}

.footer-main {
  padding-top: 38px;
  border-top: 1px solid #0000001a;
}

.footer-text,
.footer-link {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  color: #020c2d;
  text-decoration: none;
  margin: 0;
}

.footer-bottom-content {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 35px;
}

.footer-bottom-content > div.flex-row {
  gap: 30px;
}

.footer-top-content {
  justify-content: space-between;
  align-items: flex-start;
}

.footer-section-form {
  gap: 48px;
  padding-right: 10px;
  max-width: 50%;
}

.footer-section-form > img {
  height: 30px;
  width: 147px;
}

.footer-section-list {
  width: 50%;
  justify-content: space-between;
}

.footer-section-list > div > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #4a59ff;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer-list {
  gap: 20px;
}

.footer-list-item {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
}

.footer-form {
  background-color: #ffffff;
  box-shadow: 0 4px 15px 0 #aaaab940;
  border-radius: 10px;
}

.input-form-email {
  min-width: 60px;
  padding: 22px 0 20px 25px;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #020c2d;
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
}

.input-form-email::placeholder {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  color: #020c2d;
  opacity: 0.2;
}

.submit-email-btn {
  background: #4a59ff;
  border-radius: 10px;
  width: 152px;
  height: 50px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  outline: none;
  margin: 4px;
}

.loading-div {
  margin-top: 70px;
}
