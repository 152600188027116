.PrimaryButton {
  margin: 0 !important;
  border: 1px solid #ced3df;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(74, 89, 255, 0.35));
  font-size: 16px;
  flex-shrink: 100;
  line-height: 24px;
  font-family: inherit;
}

.btn-prim-blue {
  color: #ffffff;
  background-color: #4a59ff;
}

.btn-prv-white {
  color: #9093b5;
  background-color: #fefefe;
}

.PrevButton {
  font-size: 16px;
  line-height: 20px;
  color: #090324;
  font-weight: 400;
  margin: 0 !important;
  background: transparent;
  font-family: inherit;
}
