.rcw-launcher {
    background-color: rgb(2,10,67) !important;
}

.rcw-conversation-container .rcw-header{
    background-color: rgb(2,10,67) !important;

}

.rcw-sender {
    align-items: center !important;
} 


.rcw-launcher .rcw-badge {
    background-color: rgb(78,98,243) !important;
}